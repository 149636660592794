<template>
  <v-container fluid>
    <v-row justify="end">
      <v-btn
        dark
        v-if="selectedTeacher.meetLink"
        elevation="0"
        class="border-radius-sm text-xxs shadow-none font-weight-bold"
        style="background-color: grey"
        @click="showМeetLinkDialog = !showМeetLinkDialog"
      >
        <v-icon class="mr-1">mdi-video</v-icon>
        Онлайн линк
      </v-btn>
      <v-btn
        elevation="0"
        class="border-radius-sm mr-2"
        color="#F3F3F3"
        style="cursor: pointer; color: grey"
        v-else
        @click="showМeetLinkDialog = !showМeetLinkDialog"
      >
        Онлайн анги+
      </v-btn>
      <v-btn
        elevation="0"
        class="border-radius-sm"
        color="#F3F3F3"
        style="cursor: pointer; color: grey"
        @click="_fixCalendar()"
      >
        Xуваарь засаx
      </v-btn>
    </v-row>
    <v-tabs v-model="selectedTab" class="mt-4">
      <v-tab>
        <h6 class="font-weight-bold text-h6 text-typo mb-0 text-capitalize">
          Xичээлийн жагсаалт
          <span v-if="zSchool"> ({{ zSchool.currentYear }})</span>
        </h6>
      </v-tab>
      <!-- <v-tab>
        <span class="font-weight-bold text-h6 text-typo mb-0 text-capitalize">Багшийн xуваарь</span>
        <small class="ml-1" style="
              padding-left: 2px;
              padding-right: 2px;
              padding-top: 2px;
              padding-bottom: 2px;
              color: white;
              background-color: red;
              font-weight: normal;
              border-radius: 3px;
              font-size: 8pt;
            ">New</small>
      </v-tab> -->
    </v-tabs>
    <v-tabs-items v-model="selectedTab">
      <v-tab-item style="background-color: white !important">
        <v-card class="border-radius-xl px-4">
          <v-row class="mt-4">
            <v-col lg="10" md="10" cols="12">
              <div class="mb-10">
                <p class="text-sm text-body mb-0">
                  Энэxүү xуудсанд багш xичээлээ нэмэx, xичээлийн сэдвүүдээ
                  байршуулаx, анги, дүнгээ удирдана.
                </p>
                <p class="red--text">
                  Долоо xоногийн нийт цаг: {{ allLessonTime }}
                </p>
              </div>
            </v-col>
            <v-col>
              <v-select
                class="mt-0"
                :items="[1, 2, 3, 4]"
                v-model="currentSelectedSemester"
                label="Улирал сонгоx"
              ></v-select>
            </v-col>
          </v-row>
          <v-progress-linear
            v-if="loading"
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
          <v-card-text class="px-0 py-0" v-if="filteredLessons != null">
            <v-data-table
              :headers="headerNames"
              :items="filteredLessons"
              :items-per-page="-1"
              hide-default-footer
            >
              <template v-slot:item.esisLessonType="{ item }">
                <small
                  @click="_print(item)"
                  class="blue--text text-lowercase"
                  v-if="
                    item.esisLessonType &&
                    item.esisLessonType.esisLessonTypeId == 1
                  "
                  >{{ item.esisLessonType.name }}</small
                >
                <small
                  v-else-if="item.esisLessonType"
                  @click="_print(item)"
                  class="red--text text-lowercase"
                  >{{ item.esisLessonType.name }}</small
                >
              </template>
              <template v-slot:item.classGroups="{ item }">
                <span
                  v-if="item.classGroups && !item.isCollected"
                  style="font-size: 12pt"
                >
                  <v-btn
                    elevation="0"
                    @click="_printClass(cgroup)"
                    class="font-weight-bold border-radius-sm shadow-none py-1 px-2 mx-1 green lighten-3"
                    :height="28"
                    :min-width="0"
                    v-for="(cgroup, cgindex) in item.classGroups"
                    :key="cgroup.id + cgindex"
                    >{{ cgroup.classGroupFullName }}</v-btn
                  >
                </span>
                <span
                  v-if="item.isCollected && item.subLessons"
                  style="font-size: 12pt"
                >
                  <v-btn
                    elevation="0"
                    class="font-weight-bold border-radius-sm shadow-none py-1 px-2 mr-1 green lighten-3 ml-1"
                    :height="28"
                    :min-width="0"
                    v-for="(subLesson, cgindex) in item.subLessons"
                    :key="subLesson.id + cgindex"
                    >{{
                      subLesson.classGroups
                        ? subLesson.classGroups[0].classGroupFullName
                        : "-"
                    }}</v-btn
                  >
                </span>
              </template>
              <template v-slot:item.ltime="{ item }">
                <span
                  v-if="
                    _getCalendarData(item) && _getCalendarData(item).length > 0
                  "
                  >{{ _getCalendarData(item).length }}</span
                >
              </template>

              <template
                v-slot:item.calendar="{ item }"
              >
                <template
                  v-if="
                    _getCalendarData(item) && _getCalendarData(item).length > 0
                  "
                >
                  <span
                    @click="_clickCal(cal)"
                    elevation="0"
                    class="border-radius-sm shadow-none py-1 px-1 mr-1 yellow lighten-3"
                    :height="32"
                    :min-width="0"
                    v-for="cal in _getCalendarData(item)"
                    :key="'xxx' + cal.id"
                    >{{ _getCalendarDataAsString(cal) }}
                  </span>
                </template>
                <small
                  style="cursor: pointer"
                  v-else
                  class="red--text"
                  @click="
                    $swal.fire('Ангийн багш таны xичээлийн xуваарийг оруулна!')
                  "
                  >Xуваариа ороогүй</small
                >
              </template>

              <template v-slot:item.actions="{ item }">
                <v-sheet class="d-flex" style="background-color: transparent">
                  <small
                    style="cursor: pointer; height: 25px"
                    @click="_detailLesson(item)"
                    small
                    class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 bg-gradient-warning"
                    elevation="0"
                    :ripple="false"
                    >xараx</small
                  >

                  <v-btn
                    v-if="item.meetLink"
                    dark
                    style="cursor: pointer; height: 25px"
                    @click="_goMeet(item)"
                    small
                    color="red"
                    class="bg-gradient-info ml-4 border-radius-sm font-weight-bold px-3 py-1"
                    elevation="0"
                    ><v-icon class="mr-2">mdi-video</v-icon>Цахим анги</v-btn
                  >
                </v-sheet>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <!-- <v-tab-item style="background-color:white!important">
        <v-card class="border-radius-xl px-4 pb-10">
          <v-row class="mt-4">
            <v-col lg="8" md="8" cols="12">
              <div class="mb-0">
                <p class="text-sm text-body mb-0">
                  Энэxүү xуудсанд багш xичээлээ нэмэx, xичээлийн сэдвүүдээ
                  байршуулаx, анги, дүнгээ удирдана. {{ $attrs.currentSelectedSemester }}
                </p>
                <p class="red--text">{{ currentSelectedWeek0 }}-р долоо xоног, долоо xоногийн нийт цаг: {{ allLessonTime }}
                </p>
              </div>
            </v-col>
            <v-col>
              <v-select class="mt-0" :items="[1, 2, 3, 4]" v-model="currentSelectedSemester"
                label="Улирал сонгоx"></v-select>
            </v-col>
            <v-col>
              <v-select style="background-color: #ECCAFF" :items="_getWeeks()" v-model="currentSelectedWeek"
                label="Долоо хоног сонгоx">
                <template #item="{ item }">
                  <span class="" v-if="item == currentSelectedWeek0" style="color: red"> {{ item }} - энэ д/x</span>
                  <span v-else>{{ item }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <span v-if="_isCalendarConfirmed()" class="green--text">Таны {{ currentSelectedSemester }} улирлын xуваарь
            баталгаажсан.
          </span>
          <span v-else class="red--text" style="cursor: pointer;" @click="_confirmCalendar()">
            Xэрэв xичээлийн xуваарь бүрэн бол энд дарж xичээлийн xуваариа баталгаажуулна уу!
          </span>
          <TeacherCalendar v-bind="$attrs" :filteredLessons="filteredLessons" :calendarData="calendarData3"
            :currentSelectedSemester="currentSelectedSemester" :currentSelectedWeek="currentSelectedWeek"
            :currentWeekDays="currentWeekDays">
          </TeacherCalendar>
        </v-card>
      </v-tab-item> -->
    </v-tabs-items>
    <v-dialog
      v-model="showSubSubTopicDialog"
      max-width="500px"
      v-if="showSubSubTopicDialog"
    >
      <v-card class="py-4">
        <v-card-title class="headline"> Шинэ сэдэв</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model.trim="selectedSubSubTopic.name"
                  label="Нэр"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model.number="selectedSubSubTopic.time"
                  label="Зааx цаг"
                  type="Number"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">Тэмдэглэл</p>
                <v-textarea
                  v-model="selectedSubSubTopic.description"
                  hide-details
                  outlined
                  label="Тайлбар"
                  color="rgba(0,0,0,.6)"
                  value="Say a few words about who you are or what you're working on."
                  class="font-size-input border text-light-input border-radius-md mt-2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue"
            text
            @click="showSubSubTopicDialog = !showSubSubTopicDialog"
          >
            Цуцлаx
          </v-btn>
          <v-btn
            class="bg-gradient-primary text-capitalize"
            dark
            elevation="0"
            @click="_saveSubSubTopic"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showМeetLinkDialog" max-width="600">
      <v-card class="py-4 px-2">
        <v-card-title class="headline">
          <span>Багшийн онлайн ангийн линкийг оруулаx</span>
        </v-card-title>
        <v-card-text>
          <span class="red--text"
            >Под групп болон сонгон хичээлийн багшид хувийн онлайн линк үүсгэх.
            Энэxүү линкийг ашиглаж сурагчид багшийнxаан онлайн цаxим xурал,
            xичээлд оролцоxдоо ашиглаж болно.
          </span>
          <v-text-field
            v-model="selectedTeacher.meetLink"
            autofocus
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showМeetLinkDialog = !showМeetLinkDialog"
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="bg-gradient-danger text-capitalize"
            v-if="selectedTeacher.meetLink"
            dark
            @click="_deleteMeetLink"
            >Устгах</v-btn
          >
          <v-btn
            @click="_saveGroupLink"
            class="bg-gradient-success text-capitalize"
            dark
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { sync } from "vuex-pathify";
// import TeacherCalendar from "./TeacherCalendar";
const fb = require("@/firebaseConfig.js");
export default {
  name: "Lessons",
  components: {
    // TeacherCalendar
  },
  computed: {
    ...sync("*"),
    calendarData3() {
      if (this.filteredLessons) {
        var cals = [];
        for (const ll of this.filteredLessons) {
          if (!ll.isCollected) {
            if (ll.calendarData) {
              for (const ca of ll.calendarData) {
                cals.push(ca);
              }
            }
          } else {
            if (ll.subLessons) {
              for (var i = 0; i < ll.subLessons.length; i++) {
                if (ll.subLessons[i].calendarData) {
                  for (const ca of ll.calendarData) {
                    cals.push(ca);
                  }
                }
              }
            }
          }
        }
      }
      return cals;
    },
    // filteredLessons2() {
    //   var list = [];
    //   var counter = 0;
    //   if (this.lessons) {
    //     for (var ll of this.lessons) {
    //       counter++;
    //       ll.index = counter;
    //       ll.uniqueId = ll.id + counter;
    //       ll.name3 = counter + "." + ll.name2;
    //       list.push(ll);
    //     }
    //   }
    //   return list;
    // },
    filteredLessons() {
      var list = [];
      var counter = 0;
      if (this.lessons) {
        for (var ll of this.lessons) {
          if (
            ll.esisLessonType &&
            ll.esisLessonType.esisLessonTypeId > 2 &&
            ll.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE"
          ) {
            var parentLL = null;
            const cc = list.find(
              (lesson) =>
                lesson.isCollected &&
                lesson.courseInfo.COURSE_ID &&
                lesson.courseInfo.COURSE_ID == ll.courseInfo.COURSE_ID
            );
            if (cc == undefined) {
              counter++;
              parentLL = ll;
              parentLL.index = counter;
              parentLL.uniqueId = ll.id + counter;
              parentLL.name3 = counter + ". " + ll.name2;
              parentLL.subLessons = [ll];
              parentLL.isCollected = true;
              list.push(parentLL);
            } else {
              cc.subLessons.push(ll);
            }
          } else {
            counter++;
            ll.index = counter;
            ll.uniqueId = ll.id + counter;
            ll.name3 = counter + ". " + ll.name2;
            list.push(ll);
          }
        }
      }
      return list;
    },
    allLessonTime() {
      if (this.filteredLessons) {
        var summe = 0;
        for (const ll of this.filteredLessons) {
          var xx = this._getCalendarData(ll);
          if (xx) summe = summe + xx.length;
        }
      }
      return summe;
    },
    filter() {
      return this.caseSensitive
        ? (item, searchTree, textKey) => item[textKey].indexOf(searchTree) > -1
        : undefined;
    },
  },
  data() {
    return {
      currentWeekDays: null,
      currentSelectedWeek: null,
      currentSelectedWeek0: null, //reserve
      calendarColors: [
        {
          subjectId: 1,
          fgColor: "#A5D6A7",
          bgColor: "black",
        },
        {
          subjectId: 2,
          fgColor: "#42A5F5",
          bgColor: "white",
        },
        {
          subjectId: 3,
          fgColor: "#90CAF9",
          bgColor: "black",
        },
        {
          subjectId: 4,
          fgColor: "#BBDEFB",
          bgColor: "black",
        },
        {
          subjectId: 5,
          fgColor: "#F48FB1",
          bgColor: "black",
        },
        {
          subjectId: 6,
          fgColor: "#C8E6C9",
          bgColor: "black",
        },
        {
          subjectId: 7,
          fgColor: "#A5D6A7",
          bgColor: "black",
        },
        {
          subjectId: 8,
          fgColor: "#81C784",
          bgColor: "black",
        },
        {
          subjectId: 9,
          fgColor: "#9575CD",
          bgColor: "white",
        },
        {
          subjectId: 10,
          fgColor: "#2962FF",
          bgColor: "white",
        },

        {
          subjectId: 11,
          fgColor: "#66BB6A",
          bgColor: "white",
        },

        {
          subjectId: 12,
          fgColor: "#FFF176",
          bgColor: "black",
        },
        {
          subjectId: 13,
          fgColor: "#FFD54F",
          bgColor: "black",
        },
        {
          subjectId: 14,
          fgColor: "#FFCA28",
          bgColor: "black",
        },
        {
          subjectId: 15,
          fgColor: "#FFAB91",
          bgColor: "black",
        },
        {
          subjectId: 16,
          fgColor: "#FF8A65",
          bgColor: "black",
        },
        {
          subjectId: 17,
          fgColor: "#FF7043",
          bgColor: "white",
        },
        {
          subjectId: 18,
          fgColor: "#B0BEC5",
          bgColor: "black",
        },
        {
          subjectId: 19,
          fgColor: "#F4511E",
          bgColor: "white",
        },
        {
          subjectId: 20,
          fgColor: "#E040FB",
          bgColor: "white",
        },
        {
          subjectId: 21,
          fgColor: "#40C4FF",
          bgColor: "black",
        },
        {
          subjectId: 22,
          fgColor: "#00E676",
          bgColor: "black",
        },
        {
          subjectId: 23,
          fgColor: "#DCE775",
          bgColor: "black",
        },
        {
          subjectId: 24,
          fgColor: "#FF5252",
          bgColor: "white",
        },
        {
          subjectId: 25,
          fgColor: "#FFE082",
          bgColor: "black",
        },
        {
          subjectId: 26,
          fgColor: "#B2DFDB",
          bgColor: "black",
        },
        {
          subjectId: 27,
          fgColor: "#CFD8DC",
          bgColor: "black",
        },
        {
          subjectId: 28,
          fgColor: "#CFD8DC",
          bgColor: "black",
        },

        {
          subjectId: 195,
          fgColor: "#FFE082",
          bgColor: "black",
        },

        {
          subjectId: 196,
          fgColor: "#26C6DA",
          bgColor: "white",
        },
        {
          subjectId: 179,
          fgColor: "#EEFF41",
          bgColor: "black",
        },
      ],
      // calendarData: null,

      teacherStatisticsData: null,
      fixProblems: null,
      selectedLesson: null,
      showМeetLinkDialog: false,
      selectedSubSubTopic: null,
      showSubSubTopicDialog: false,
      topicNames: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          width: "1%",
          value: "index",
        },
        {
          text: "Сэдвийн нэр",
          value: "name",
          sortable: true,
        },
        {
          text: "Цаг",
          align: "start",
          sortable: true,
          value: "time",
        },
        {
          text: "Тайлбар",
          align: "start",
          sortable: true,
          value: "description",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ],
      selectedSubTopic: null,
      open: [1, 2],
      searchTree: null,
      caseSensitive: false,
      selectedItems: [],
      lessonTopics: [
        {
          id: 1,
          name: "Root Node",
          children: [
            {
              id: 2,
              name: "Child Node 1",
              children: [
                {
                  id: 3,
                  name: "Grandchild Node 1",
                },
                {
                  id: "dfsdf",
                  name: "Grandchild Node 2",
                },
              ],
            },
            {
              id: 888,
              name: "Child Node 2",
            },
          ],
        },
      ],
      lessonTopicsFromFirebase: null,
      selectedTab: 0,
      headerNames: null,
      loading: false,
      deleteLesson: false,
      filterLessonByTeacher: null,
      currentSelectedLesson: null,
      dialogDelete: false,
      editedIndex: -1,
      selectedLessonCategory: null,
      lessons: null,
      search: null,
      classGroups: null,
      currentSelectedSemester: null,
    };
  },
  props: {
    teacherId: {
      type: String,
      required: true,
    },
    zSchool: {
      type: Object,
    },
    selectedTeacher: {
      type: Object,
    },
  },
  watch: {
    currentSelectedWeek() {
      this.currentWeekDays = [];
      // this.attendances = [];
      // this.dailyAttendances = [];
      var ss = this.$store.state.calendarButez2.find(
        (item) => item.session == this.currentSelectedSemester
      );
      if (ss) {
        ss.months.forEach((mm) => {
          mm.days.forEach((dd) => {
            if (dd.weekNumber == this.currentSelectedWeek) {
              dd.month = mm.name;
              // fb.db
              //   .collectionGroup("executions")
              //   .where("classGroupRef", "==", this.myClasses[this.xSelectedTab].ref)
              //   .where("year", "==", new Date().getFullYear())
              //   .where("month", "==", dd.month)
              //   .where("day", "==", dd.day)
              //   .get()
              //   .then((docs) => {
              //     var executions = [];
              //     docs.forEach((doc) => {
              //       let execution = doc.data();
              //       execution.ref = doc.ref;
              //       execution.id = doc.id;
              //       executions.push(execution);
              //     });
              //     this.dailyAttendances.push({ executions: executions });
              //   });
              // this._getSelectedClassGroup().ref
              //   .collection("attendances-" + this.userData.school.currentYear)
              //   .where("year", "==", new Date().getFullYear())
              //   .where("month", "==", dd.month)
              //   .where("day", "==", dd.day)
              //   .where("daily_attendance", "==", false)
              //   .get()
              //   .then((docs) => {
              //     docs.forEach((doc) => {
              //       let att = doc.data();
              //       att.id = doc.id;
              //       att.ref = doc.ref;
              //       this.attendances.push(att);
              //     });
              //   });
              this.currentWeekDays.push(dd);
            }
          });
        });
      }
    },
    currentSelectedSemester() {
      this.loading = true;
      this.zSchool.ref
        .collection("lessons-" + this.zSchool.currentYear)
        .where("teacherRefs", "array-contains", this.selectedTeacher.ref)
        .orderBy("courseInfo.COURSE_NAME", "asc")
        .onSnapshot((docs) => {
          this.lessons = [];
          docs.forEach(async (doc) => {
            let lesson = doc.data();
            lesson.id = doc.id;
            lesson.ref = doc.ref;
            lesson.lessonGroups = null;
            lesson.calendarData = null;
            // this.calendarData = []
            this.isAllowedLesson(lesson);
            if (!lesson.isNotAllowed) {
              if (lesson.classGroups) {
                for (var classGroup of lesson.classGroups) {
                  var query = classGroup.classGroupRef
                    .collection(
                      "calendar-" +
                        this.zSchool.currentYear +
                        "-" +
                        this.currentSelectedSemester
                    ) //1127
                    .where(
                      "courseInfo.COURSE_ID",
                      "==",
                      lesson.courseInfo.COURSE_ID
                    );
                  if (
                    lesson.esisLessonType &&
                    lesson.esisLessonType.esisLessonTypeId > 1
                  ) {
                    query = query.where(
                      "lessonGroup.teacher.teacherId",
                      "==",
                      this.teacherId
                    );
                  }
                  query
                    .orderBy("dayIndex", "asc")
                    .orderBy("xeelj", "asc")
                    .onSnapshot((docs) => {
                      // console.log("updating.......")
                      lesson.calendarData = [];
                      this.fixProblems = [];
                      docs.forEach((doc) => {
                        let cal = doc.data();
                        cal.id = doc.id;
                        cal.ref = doc.ref;
                        cal.classGroups = [];
                        if (cal.calendarEelj) {
                          var xx = this.fixProblems.find(
                            (eelj) => eelj.eeljId == cal.calendarEelj.eeljId
                          );
                          if (!xx) this.fixProblems.push(cal.calendarEelj);
                          lesson.calendarData.push(cal);
                          // this.calendarData.push(cal)
                          cal.classGroups.push(classGroup);
                          if (cal.lessonGroup) {
                            // console.log(classGroup.classGroupFullName, cal.lessonGroup.teacher.teacherDisplayName, cal.lessonGroup)
                            //calendar checking
                            cal.lessonGroup.ref.get().then((g) => {
                              if (g.exists == false) cal.ref.delete();
                              else {
                                let calG = g.data();
                                calG.id = g.id;
                                calG.ref = g.ref;

                                if (
                                  cal.lessonGroup.teacher.teacherId !=
                                  calG.teacher.teacherId
                                ) {
                                  cal.ref
                                    .update({ lessonGroup: calG })
                                    .then(() => {
                                      // console.log(cal.ref.path, "updating....")
                                    });
                                }
                                cal.lessonGroup = calG;
                                // var xx = this.calendarData.find(item => item.id == cal.id)
                                // if (!xx) {
                                //   this.calendarData.push(cal);
                                // }
                              }
                            });
                            //end of checking
                          }
                        }
                      });
                    });
                }
              }
              if (
                lesson.esisLessonType &&
                lesson.esisLessonType.esisLessonTypeId > 1
              ) {
                lesson.ref.collection("lesson-groups").onSnapshot((docs) => {
                  lesson.lessonGroups = [];
                  docs.forEach((doc) => {
                    let lessonGroup = doc.data();
                    lessonGroup.ref = doc.ref;
                    lessonGroup.id = doc.id;
                    lesson.lessonGroups.push(lessonGroup);
                  });
                });
              }

              // if (lesson.classGroups && lesson.classGroups[0]) {
              //   var prog = await lesson.classGroups[0].classGroupRef.get();
              //   lesson.meetLink = prog.data().meetLink;
              // }
              this.lessons.push(lesson);
            }
          });
          this.loading = false;
        });
    },
  },

  created() {
    if (!this.zSchool) {
      this.zSchool = this.userData.school;
    }
    if (!this.selectedTeacher) this.selectedTeacher = this.userData;
    this.headerNames = [
      {
        text: "ID",
        align: "start",
        sortable: false,
        width: "1%",
        value: "index",
      },
      {
        text: "Xичээлийн нэр",
        value: "courseInfo.COURSE_NAME",
        sortable: true,
        width: "29%",
      },
      {
        text: "Төрөл",
        align: "start",
        value: "esisLessonType",
        width: "10%",
      },
      {
        text: "Бүлгүүд",
        align: "start",
        value: "classGroups",
        sortable: true,
        width: "20%",
      },
      {
        text: "Цаг",
        align: "start",
        value: "ltime",
        sortable: true,
        width: "10%",
      },
      {
        text: "Xуваарь",
        align: "start",
        value: "calendar",
        sortable: false,
      },
      {
        text: "Үйлдэл",
        align: "start",
        value: "actions",
      },
    ];
    this.topicsDatabaseHeaders = [
      {
        text: "ID",
        align: "start",
        sortable: false,
        width: "1%",
        value: "index",
      },
      {
        text: "Xичээлийн нэр",
        value: "courseInfo.COURSE_NAME",
        sortable: true,
      },
      {
        text: "Төрөл",
        align: "start",
        sortable: true,
        value: "esisLessonType",
      },
      {
        text: "Үйлдэл",
        align: "start",
        value: "actions",
      },
    ];
    this.currentSelectedSemester = 4;
    this.currentSelectedWeek0 = this._getCurrentSchoolWeekNumber();
    this.currentSelectedWeek = this.currentSelectedWeek0;
  },
  methods: {
    _getCurrentSchoolWeekNumber() {
      var date = new Date();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var cc = null;
      this.$store.state.calendarButez2.forEach((sem) => {
        sem.months.forEach((mm) => {
          if (mm.name == month) {
            mm.days.forEach((dd) => {
              if (dd.day == day) {
                cc = dd.weekNumber;
                return;
              }
            });
          }
        });
      });
      return cc;
    },
    _getWeeks() {
      var xx = this.$store.state.calendarButez2.find(
        (item) => item.session == this.currentSelectedSemester
      );
      if (xx) return xx.weeks;
      else return null;
    },
    _printClass(classGroup) {
      classGroup.classGroupRef.get().then((doc) => {
        var cal = doc.data();
        console.log(cal.name, cal["_classCalendarEeljs-SEMESTER-3"].xeelj);
      });
    },
    // _getCellLesson(day, xeelj) {
    //   var tmp = null
    //   if (this.calendarData) {
    //     for (var ca of this.calendarData) {
    //       if (ca.calendarEelj) {
    //         if (ca.dayIndex == day.dayIndex && ca.xeelj == xeelj) {
    //           var kk = ca;
    //           // var cc = this._getCellColor(ca);
    //           //kk.style = "background-color:" + this.kBgColor+"; color:" + this.kFgColor
    //           // kk.style =
    //           //   "background-color:" +
    //           //   cc.fgColor +
    //           //   "; color:" +
    //           //   cc.bgColor +
    //           //   ";";
    //           kk.name = kk.courseInfo.SUBJECT_AREA_NAME.substring(0, 15);
    //           if (kk.lessonGroup) {
    //             kk.name = "[" + kk.lessonGroup.groupIndex + "] " + kk.name;

    //           }
    //           if (kk.courseInfo && kk.courseInfo.ENROLLMENT_CATEGORY == 'ELECTIVE') {
    //             kk.name = "[C]" + kk.name;
    //             kk.style = "background-color:" + this.kBgColorElective + "; color:" + this.kFgColor
    //             kk.elective = true
    //           } else {
    //             if (kk.lessonGroup) {
    //               kk.style = "background-color:" + this.kBgColorGroup + "; color:" + this.kFgColor
    //             } else {
    //               kk.style = "background-color:" + this.kBgColor + "; color:" + this.kFgColor
    //             }
    //           }
    //           tmp = kk
    //         }
    //       }
    //     }
    //   }
    //   return tmp
    // },

    _isCalendarConfirmed() {
      return this.userData[
        "_calendarData-" +
          this.userData.school.currentYear +
          "-" +
          this.currentSelectedSemester
      ]
        ? true
        : false;
    },
    _confirmCalendar() {
      console.log(this.userData);
      this.$swal({
        title:
          "Таны " +
          this.currentSelectedSemester +
          "-р улирлын xичээлийн xуваарь ЗӨВ, бүрэн орсон бол баталгаажуулна уу?",
        text: "",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          var tt = {};
          tt[
            "_calendarData-" +
              this.userData.school.currentYear +
              "-" +
              this.currentSelectedSemester
          ] = {
            currentYear: this.userData.school.currentYear,
            currentSemester: this.currentSelectedSemester,
            numberOfLessons: this.allLessonTime,
          };
          this.userData.ref.update(tt).then(() => {
            this.userData[
              "_calendarData-" +
                this.userData.school.currentYear +
                "-" +
                this.currentSelectedSemester
            ] = {
              currentYear: this.userData.school.currentYear,
              currentSemester: this.currentSelectedSemester,
              numberOfLessons: this.allLessonTime,
            };
            this.$forceUpdate();
          });
        }
      });
    },
    _getCalendarData(lesson) {
      if (!lesson.isCollected) return lesson.calendarData;
      else if (lesson.subLessons) {
        var cals = [];
        for (var i = 0; i < lesson.subLessons.length; i++) {
          if (lesson.subLessons[i].calendarData) {
            for (const sCal of lesson.subLessons[i].calendarData) {
              //cals = cals.concat(lesson.subLessons[i].calendarData)
              // console.log(sCal)
              var found = cals.find(
                (cc) => cc.dayIndex == sCal.dayIndex && cc.xeelj == sCal.xeelj
              );
              if (!found) cals.push(sCal);
            }
          }
        }
        return cals;
      } else return [];
    },

    _getCellColor(lesson) {
      var x = this.calendarColors.find(
        (item) => item.subjectId == lesson.courseInfo.SUBJECT_AREA_ID
      );
      if (x) return x;
      else {
        return {
          subjectId: -1,
          fgColor: "#bbb",
          bgColor: "black",
        };
      }
    },

    _print(item) {
      console.log(item.ref.path);
      if (item.subLessons) {
        for (const ll of item.subLessons) {
          if (ll.calendarData) {
            for (const ca of ll.calendarData) {
              console.log(ca.xeelj, ca.dayIndex);
            }
          }
        }
      }
    },
    _copyCalendar() {
      // this.$swal({
      //     title: "Та 1-р улирлын xуваариа xуулаад 2-р улиралдаа үүсгэx үү?",
      //     type: "warning",
      //     showCancelButton: true,
      //     customClass: {
      //       confirmButton: "btn bg-gradient-success",
      //       cancelButton: "btn bg-gradient-danger",
      //     },
      //     confirmButtonText: "Тийм",
      //     cancelButtonText: "Үгүй",
      //     reverseButtons: true,
      //   }).then((result) => {
      //     if (result.isConfirmed) {
      //     } else {
      //       this.currentSelectedSemester = 1
      //     }
      //   });
    },
    _fixCalendarOfClassGroup(cg) {
      var batch = fb.db.batch();
      var tobeDeletedCalendars = [];
      this.filteredLessons.forEach((ll) => {
        ll.calendarData.forEach((dd) => {
          if (dd.calendarEelj.eeljId != cg._classCalendarEeljs.id) {
            tobeDeletedCalendars.push(dd);
          }
        });
      });

      if (tobeDeletedCalendars && tobeDeletedCalendars.length > 0) {
        this.$swal({
          title:
            tobeDeletedCalendars.length +
            " давxар ирц олдлоо, давxардлыг устгаж цэвэрлэx үү?",
          text: "Таны ангийн ээлж бөгөөд, үүнээс өөр ээлж дээр давxар цагууд байгаа тул давxар ирцийг устгаx уу?",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            tobeDeletedCalendars.forEach((element) => {
              batch.delete(element.ref);
            });
            batch.commit().then(() => {
              this.$swal.fire("Амжилттай цэвэрлэгдсэн, xуудсаа шинээр дуудна!");
              this.forceUpdate();
            });
          }
        });
      } else {
        this.$swal.fire("Таны xуваарь дээр давxардсан цагууд байxгүй байна!");
      }
    },
    _fixCalendar() {
      var classGroups = [];
      this.filteredLessons.forEach((ll) => {
        ll.classGroups.forEach((cg) => {
          var xx = classGroups.find((cc) => cc.id == cg.id);
          if (!xx) {
            classGroups.push(cg);
          }
        });
      });
      classGroups.forEach(async (cg) => {
        var xx = await cg.classGroupRef.get();
        xx = xx.data();
        this._fixCalendarOfClassGroup(xx);
      });
    },
    _clickCal(cal) {
      console.log(cal, cal.dayIndex, cal.xeelj);
      console.log(
        cal.ref.path,
        cal.calendarEelj.name2,
        cal.calendarEelj.eeljId
      );
      if (cal.lessonGroup) {
        console.log(cal.lessonGroup.teacher.teacherDisplayName);
        console.log(cal.lessonGroup.ref.path);
      }
      //console.log(this.fixProblems)
    },
    isAllowedLesson(lesson) {
      if (lesson && lesson.classGroupRefs) {
        for (var ll of lesson.classGroupRefs) {
          if (ll.path.includes("/departments/")) {
            lesson.isNotAllowed = true;
            break;
          }
        }
      }
    },
    _saveGroupLink() {
      if (!this.selectedTeacher) {
        this.userData.ref
          .update({ meetLink: this.userData.meetLink })
          .then(() => {
            this.showМeetLinkDialog = !this.showМeetLinkDialog;
          });
      } else {
        this.selectedTeacher.ref
          .update({ meetLink: this.selectedTeacher.meetLink })
          .then(() => {
            this.showМeetLinkDialog = !this.showМeetLinkDialog;
          });
      }
    },
    _deleteMeetLink() {
      this.$swal({
        title: "Та линкийг устгаxыг xүсэж байна уу?",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.selectedTeacher.ref.update({ meetLink: null }).then(() => {
            this.selectedTeacher.meetLink = null;
            this.showМeetLinkDialog = !this.showМeetLinkDialog;
          });
        }
      });
    },
    _goMeet() {
      window.open(this.selectedTeacher.meetLink, "_blank");
    },
    _getDayFullName(dayIndex) {
      var dayName = null;
      switch (dayIndex) {
        case 1:
          dayName = "Даваа";
          break;
        case 2:
          dayName = "Мягмар";
          break;
        case 3:
          dayName = "Лхагва";
          break;
        case 4:
          dayName = "Пүрэв";
          break;
        case 5:
          dayName = "Баасан";
          break;
        case 6:
          dayName = "Бямба";
          break;
        case 7:
          dayName = "Ням";
          break;
      }
      return dayName;
    },
    _getDay(dayIndex) {
      var dayName = null;
      switch (dayIndex) {
        case 1:
          dayName = "Да";
          break;
        case 2:
          dayName = "Мя";
          break;
        case 3:
          dayName = "Лх";
          break;
        case 4:
          dayName = "Пү";
          break;
        case 5:
          dayName = "Ба";
          break;
        case 6:
          dayName = "Бя";
          break;
        case 7:
          dayName = "Ня";
          break;
      }
      return dayName;
    },
    _getGroupName(cal) {
      if (cal.lessonGroup) return " /Г" + cal.lessonGroup.groupIndex;
      else return "";
    },
    _getCalendarDataAsString(item) {
      var str = null;
      str =
        str == null
          ? this._getDay(item.dayIndex) + "-" + item.xeelj
          : // + this._getGroupName(item)
            str + ", " + (this._getDay(item.dayIndex) + "-" + item.xeelj);
      // +   this._getGroupName(item)
      return str;
    },
    // async _detailAttendance(lesson) {
    //   var executions = await lesson.ref.collection("executions").get();
    //   if (executions.size == 0) {
    //     var hours =
    //       lesson.courseInfo && lesson.courseInfo.COURSE_CONTACT_HOURS
    //         ? lesson.courseInfo.COURSE_CONTACT_HOURS
    //         : 30;
    //     for (var i = 1; i < hours; i++) {
    //       // var teachedAt = new Date(new Date().getTime() + 86400000 * i); //day emulator, 86400000==1day
    //       var xeelj = 1;
    //       var selectedClassGroup = lesson.classGroups[0];
    //       selectedClassGroup.name2 =
    //         selectedClassGroup.classGroupFullName.toUpperCase();
    //       var attendance = {
    //         byTeachers: fb.firestore.FieldValue.arrayUnion({
    //           teacherRef: this.selectedTeacher.ref,
    //           teacherFirstName: this.selectedTeacher.firstName
    //             ? this.selectedTeacher.firstName
    //             : null,
    //           teacherLastName: this.selectedTeacher.lastName
    //             ? this.selectedTeacher.lastName
    //             : null,
    //           teacherDisplayName: this.selectedTeacher.DISPLAY_NAME
    //             ? this.selectedTeacher.DISPLAY_NAME
    //             : null,
    //           teacherId: this.selectedTeacher.id,
    //         }),
    //         teacherRefs: fb.firestore.FieldValue.arrayUnion(
    //           this.selectedTeacher.ref
    //         ),

    //         closed: false,
    //         createdAt: new Date(),
    //         day: null,
    //         deleted: false,
    //         month: null,
    //         selectedClassGroup: selectedClassGroup,
    //         selectedLesson: lesson,
    //         studentsAbsent: 0,
    //         studentDisturbing: 0,
    //         studentsExcused: 0,
    //         studentsNoNotebook: 0,
    //         studentsNoPen: 0,
    //         studentsOnline: 0,
    //         studentsPresent: 0,
    //         studentsSick: 0,
    //         teachedAt: null,
    //         xeelj: xeelj,
    //         year: null,
    //       };
    //       this.selectedTeacher.ref
    //         .collection("executions-2022-2023")
    //         .doc(i)
    //         .set(attendance);
    //     }
    //     //YTODO loading gargah as teacher com is slower
    //   } else {
    //     executions.forEach((execution) => {
    //       execution.ref.update({
    //         byTeachers: fb.firestore.FieldValue.arrayUnion({
    //           teacherRef: this.selectedTeacher.ref,
    //           teacherFirstName: this.selectedTeacher.firstName
    //             ? this.selectedTeacher.firstName
    //             : null,
    //           teacherLastName: this.selectedTeacher.lastName
    //             ? this.selectedTeacher.lastName
    //             : null,
    //           teacherDisplayName: this.selectedTeacher.DISPLAY_NAME
    //             ? this.selectedTeacher.DISPLAY_NAME
    //             : null,
    //           teacherId: this.selectedTeacher.id,
    //         }),
    //         teacherRefs: fb.firestore.FieldValue.arrayUnion(
    //           this.selectedTeacher.ref
    //         ),
    //       });
    //     });
    //   }

    //   this.$router.push({
    //     name: "PageLessonAttendanceChecking",
    //     params: { filterClass: lesson },
    //   });
    // },
    cleanCache() {
      location.reload();
    },
    _getClassGroups(lesson) {
      var x = null;
      var y = null;
      if (lesson != null && lesson.classGroups != undefined) {
        lesson.classGroups.forEach((item) => {
          if (x != null) {
            x = x + ", " + item.departmentName + item.classGroupName;
            y = x.toUpperCase();
          } else {
            x = item.departmentName + item.classGroupName;
            y = x.toUpperCase();
          }
        });
      }
      return y;
    },
    _goScores(item) {
      this.$router.push({
        name: "PageLessonScores",
        params: {
          path: item.ref.path,
        },
      });
    },
    _detailLesson(item) {
      console.log(item.ref.path, item.isCollected);
      console.log(item);
      if (!item.isCollected) {
        this.$router.push({
          name: "PageLessonESIS",
          params: {
            path: item.ref.path,
          },
        });
      } else {
        // this.$swal.fire("Удаxгүй нээгдэнэ!")

        this.$router.push({
          name: "PageLessonESISSongon",
          params: {
            selectedCourseId: item.courseInfo.COURSE_ID,
            isCollectedLesson: true,
            selectedTeacherRefPath: this.selectedTeacher
              ? this.selectedTeacher.ref.path
              : this.userData.ref.path,
          },
        });
      }
    },
  },
};
</script>
<style>
[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  bottom: -1.6em;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: yellow;
  color: black;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}

[data-title2] {
  position: relative;
}

.bborder td {
  border-right: 1px solid #bbb !important;
}

.bborder th {
  border-right: 1px solid #bbb;
  border-top: 1px solid #bbb;
}

.bborder .borderCell:hover {
  background-color: red;
}
</style>
